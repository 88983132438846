(function($) {
    "use strict";
    var chatMessages = [
        {
            name: "ms1",
            msg: "👋Hi​! I'm VELA. I hope you're learning some amazing things on the learning platform. Let me know if you have any questions about using the platform.",
            delay: 300,
            align: "right",
            showTime: true,
            time: "19:58",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms2",
            msg: "I'm not able to log in to my account. Can you help me?",
            delay: 3000,
            align: "left",
            showTime: true,
            time: "20:01",
            img: "assets/img/author-image/chat_person_small.png"
        },
        {
            name: "ms3",
            msg: "Sure! I understand, you are trying to log in to your account and may have received the error message 'Invalid login'. Please follow these steps to fix the issue.",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:03",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms4",
            msg: "1) Check if you’ve typed your credentials correctly.",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:04",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms5",
            msg: "2) Try the option 'Forgotten your username or password?' and follow the instructions on your screen.",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:04",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms6",
            msg: "If the above steps don't fix the problem, please connect with your institute's admin. They will help you out!",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:05",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms7",
            msg: "Okay, thank you!",
            delay: 3000,
            align: "left",
            showTime: true,
            time: "20:08",
            img: "assets/img/author-image/chat_person_small.png"
        },
        {
            name: "ms8",
            msg: "Great! Do you want to know anything else?",
            delay: 3000,
            align: "right",
            showTime: true,
            time: "20:10",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms9",
            msg: "Can you tell me how to download my certificate?",
            delay: 3000,
            align: "left",
            showTime: true,
            time: "20:13",
            img: "assets/img/author-image/chat_person_small.png"
        },
        {
            name: "ms10",
            msg: "Yes, it is very easy to download your certificate.",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:14",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms11",
            msg: "Each time you finish taking a quiz or a course, you will get the option to download your certificate. Just click on the download button and save the certificate.",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:14",
            img: "assets/img/author-image/vela_small.png"
        },
        {
            name: "ms12",
            msg: "Great! Thank you.",
            delay: 3000,
            align: "left",
            showTime: true,
            time: "20:16",
            img: "assets/img/author-image/chat_person_small.png"
        },
        {
            name: "ms13",
            msg: "Let me know, if you have any other questions, I'm here to assist you any time! 🙂 ",
            delay: 2000,
            align: "right",
            showTime: true,
            time: "20:18",
            img: "assets/img/author-image/vela_small.png"
        }
    ];
    var chatDelay = 0;
    var chatDelay2, chatDelay3, scrollDelay, chatTimeString, msgname, msginner, spinner;

    function onRowAdded() {
        $('.chat-container').animate({
            scrollTop: $('.chat-container').prop('scrollHeight')
        });
    };
    $.each(chatMessages, function(index, obj) {
        chatDelay = chatDelay + 1000;
        chatDelay2 = chatDelay + obj.delay;
        chatDelay3 = chatDelay2 + 10;
        scrollDelay = chatDelay;
        chatTimeString = " ";
        msgname = "." + obj.name;
        msginner = ".messageinner-" + obj.name;
        spinner = ".sp-" + obj.name;
        if (obj.showTime == true) {
            chatTimeString = "<span class='message-time'>" + obj.time + "</span>";
        }
        $(".chat-message-list").append("<li class='message-" + obj.align + " " + obj.name + "' hiddenn><div class='sp-" + obj.name + "'><span class='spinme-" + obj.align + "'><div class='spinner'><div class='bounce1'></div><div class='bounce2'></div><div class='bounce3'></div></div></span></div><div class='messageinner-" + obj.name + "' hiddenn><img src='" + obj.img +"'><span class='message-text'>" + obj.msg + chatTimeString + "</span></div></li>");

        $(msgname).delay(chatDelay).fadeIn();
        $(spinner).delay(chatDelay2).hide(1);
        $(msginner).delay(chatDelay3).fadeIn();
        setTimeout(onRowAdded, chatDelay);
        setTimeout(onRowAdded, chatDelay3);
        chatDelay = chatDelay3;
    });
}(jQuery))
